<template>
  <div class="flex flex-col space-y-4">
    <div class="text-xl font-bold">{{ detail.title }}</div>
    <img
      class="rounded-lg shadow-xl object-fill object-center h-64 w-full"
      :src="detail.imageUrl">
    <div class="text-base text-gray-400 text-right">{{ date }}</div>
    <YoutubeIframe
      v-if="isYoutube"
      :youtube-id="detail.youtubeVideoId"
    />
    <div class="text-base text-gray-800" v-html="detail.contents">
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
import { mapState, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { commonConfig } from '@/config'
const YoutubeIframe = defineAsyncComponent(() => import('@/components/common/YoutubeIframe.vue'))
export default {
  name: 'DetailArticle',
  components: {
    YoutubeIframe
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('article', ['detail']),
    articleId () {
      return this.$route.params.id
    },
    date () {
      return dateFormater(this.detail.date)
    },
    isYoutube () {
      return this.detail.type === commonConfig.typeYoutube
    }
  },
  methods: {
    ...mapActions('article', ['fetchDetail', 'resetDetail']),
    initPage () {
      this.resetDetail()
      this.fetchDetail({
        id: this.articleId,
        fail: this.handleFail
      })
    },
    handleFail () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
